/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is configuration file with public information
 */

import countries from '../const/countries';

const config = {
    env: 'beta',
    webappUrl: 'https://beta-webapp.myfeelix.de/',
    isDebug: true,
    features: { cashback: true },

    api: {
        timeout: 45000,
        getTokenPath: '/api/private/v1/token/jwt',
    },

    storesExpiretion: {
        customer: 4320000, //12 hours
        contracts: 360000, //1 hour
        data: 4320000, //12 hours
    },

    urls: {
        brokerTemplate: 'https://intranet.myfeelix.de/~L0QH7',
        privacyTemplate: 'https://intranet.myfeelix.de/~WNqxE',
        impressumLink: 'https://myfeelix.de/impressum/',
        newTicket: 'https://myfeelix.de/Angebotsservice/',
    },

    bitrix24Urls: {
        supportUrl: 'https://myfeelix.de/Angebotsservice/',
        contractPopupUrl: 'https://myfeelix.de/Angebotsservice/',
        cancelBroker: 'https://myfeelix.de/Angebotsservice/',
    },

    finAPI: {
        env: 'live',
        timeout: 60000,
        profileId: 'e7897066-c975-4ce5-b6d2-676538be1c51',
        urls: {
            access: 'https://live.finapi.io/api/v1',
            webform: 'https://webform-live.finapi.io/api',
            data: 'https://di-live.finapi.io/api',
        },
    },

    youtube: {
        onboardingFinapi: 'LpDfl6CdpaE',
        onboardingInfo: '0ctmX_7mGqk',
        onboardingSign: '0ctmX_7mGqk',
    },

    dateFormat: {
        week: 'MM/DD',
        serverDate: 'YYYY-MM-DD',
        serverDateWithTime: 'YYYY-MM-DD HH:mm:ss',
        date: 'DD.MM.YYYY',
        logDate: 'YYYYMMDDHms',
    },

    digit: {
        decimalSeparator: ',',
        separator1000: '.',
    },

    countries: ['deutschland', 'austrian'],

    currency: {
        title: 'EUR',
        sign: '€',
    },

    validationCountryCode: countries.deutschland.code,
};
export default config;
