/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a header for secure pages like dashboard, contracts list, etc
 */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import SlideMenu from './SlideMenu';
import pageURLs from '../../const/pages';
import trans from '../../trans';

import IconLogoBlue from '../../assets/icon-logo-blue.svg';
import IconLogoMobileBlue from '../../assets/icon-logo-mobile-blue.png';
import IconSlideMenu from '../../assets/icon-slide-menu.png';
import config from '../../config';

export default function Header({ isBack, children, isTopMenu, isSlideMenu, className, title, selected, noBorder }) {
    //state variables
    const [isOpenedSlide, setIsOpenedMenu] = useState(false);

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //determine class of the menu
    let clssName = 'header-secure';
    if (className) {
        clssName += ' ' + className;
    }
    if (noBorder) {
        clssName += ' no-border';
    }
    if (isMobile) {
        clssName += ' header-mobile';
    }

    //handler for open slide menu
    const openSlideMenu = () => {
        setIsOpenedMenu(true);
    };

    return (
        <div className={clssName}>
            <div className="wrapper-big">
                <div className="top">
                    <div className="left">
                        {!!isBack && <div className="back" onClick={() => navigate(-1)} />}
                        {!isBack && (
                            <div className="logo" onClick={() => navigate(pageURLs.secureDashboard)}>
                                <img src={isMobile ? IconLogoMobileBlue : IconLogoBlue} alt="logo" />
                            </div>
                        )}
                    </div>
                    <div className="right">
                        {!isMobile && !!isTopMenu && (
                            <div className="menu-top">
                                <div className={selected === pageURLs.secureContractsList ? 'selected' : ''} onClick={() => navigate(pageURLs.secureContractsList)}>
                                    {trans.t('header', 'menuTopContracts')}
                                </div>
                                <div onClick={() => window.open(config.bitrix24Urls.contractPopupUrl, '_blank').focus()}>{trans.t('header', 'menuTopSupport')}</div>
                            </div>
                        )}
                        {!!isSlideMenu && (
                            <div className="menu-slide-icon" onClick={openSlideMenu}>
                                <img src={IconSlideMenu} alt="slide" />
                            </div>
                        )}
                    </div>
                </div>
                {!!title && <h1>{title}</h1>}
                {!!children && <div className="children">{children}</div>}
            </div>
            {!!isTopMenu && <SlideMenu isOpened={isOpenedSlide} closeFunc={() => setIsOpenedMenu(false)} />}
        </div>
    );
}
